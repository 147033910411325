import { ColorModeScript } from "@chakra-ui/react";
import React from "react";
import { PageContextProvider } from "src/hooks/usePageContext";
import "./style.css";
import { MyChakraProvider } from "./theme";
import type { PageContext } from "./types";

export function PageShell({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) {
  return (
    <>
      <ColorModeScript type="cookie" initialColorMode="light" />
      <PageContextProvider pageContext={pageContext}>
        <MyChakraProvider>{children}</MyChakraProvider>
      </PageContextProvider>
    </>
  );
}
